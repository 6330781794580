import Pic1 from './5.jpeg';
import Pic2 from './6.jpeg';
import Pic3 from './7.jpeg';

import UIf1 from './12.jpg';
import UIf2 from './7.jpg';
import UIf3 from './6.jpg';
import api from '../../../Api';
import React, { useEffect, useState } from 'react';




function FromBlog() {
const [Blog_data, SetData] = useState([]);
  useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await api.get('/blog/featuredposts/');
      // Handle the response data
      console.log(response.data);
      SetData(response.data)
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);


  return (
    <section class="mt-7 mt-lg-8">
      <div class="container">
        <h2 class="text-darkblue">
          From the blog
        </h2>
        <div class="svg-icon svg-icon-xl text-warning">
          <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
            <title>ionicons-v5-e</title>
            <line x1="400" y1="256" x2="112" y2="256" style={{ fill: "none", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "32px" }} />
          </svg>
        </div>

        <div class="row mt-5" style={{textAlign:'left'}}>

          
         {Blog_data.map((data, index) => (
           <div class="col-lg-4 my-4">
            <a href="#!" class="d-block bg-cover h-200 shadow-light hover-lift-light" style={{ backgroundImage: `url(${'https://api.devzhive.com'}${data['image']})` }}>
            </a>
            <a href={'/blog/posts/'+data['slug']} class="d-block h5 text-dark text-decoration-none mt-4">
              {data['title']}
            </a>
            <p class="text-secondary font-size-sm mt-3">
              {data['meta_description']}
            </p>
            <div class="avatar avatar-sm mr-2">
              <img src={UIf1} class="avatar-image rounded-circle" alt="" />
            </div>
            <span class="text-uppercase font-size-xs font-weight-bolder">
              
              {data['posted_by'].username}
            </span>
          </div>
          
        ))}
         


         


        </div>
      </div>
    </section>

  );
}

export default FromBlog;
function Map() {
    return (
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d23099.894287574047!2d12.568648007651053!3d55.6746014139839!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2smx!4v1588710157300!5m2!1ses-419!2smx"
            width="100%"
            height="500"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            title="Google Maps"
        ></iframe>
    )
}
export default Map;
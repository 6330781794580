import Navbar from "./components/Project_details/Navbar/Navbar";
import Main_Wrapper from "./components/Project_details/Main_Wrapper/Main_Wrapper";
import Footer from "./components/Home/Footer/footer";
function Project(){
    return(
        <div className="Project">

       
        <Navbar/>
        <Main_Wrapper/>
        <Footer/>
         </div>
    )
}

export default Project;
import Image50 from './resources/50.jpeg';
import Image51 from './resources/51.jpeg';
import Image52 from './resources/52.jpeg';
import Image46 from './resources/46.jpeg'
import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';
import api from '../../../Api';



function Featured_post(){
  const { slug } = useParams();
  const [blogPosts, setblogPosts] = useState(null);
  useEffect(() => {
    const fetchProject = async () => {
      try {
        
        const response = await api.get(`blog/posts/`);
        setblogPosts(response.data.results);
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };
    fetchProject();
  }, [slug]);
  useEffect(() => {
  console.log(blogPosts);
}, [blogPosts]);

    return (
       <section class="mt-6">
    <div class="container">
    
        {blogPosts ? (blogPosts.map((data, index) => (
      
       index % 2 === 0 ?(
         <div class="timeline">

       
          <div class="timeline-post">
            <div class="timeline-post-marker"></div>

            <div class="timeline-post-content hover-mask h-300 rounded-lg shadow-light">
            
              <div class="hover-mask-image" style={{backgroundImage:`url(${'https://api.devzhive.com'}${data['image']})`}}>
              </div>

              <a href="#!" class="hover-mask-content d-flex flex-column opacity-100 text-decoration-none p-4">  
                <span class="d-flex text-white text-uppercase-xs font-weight-bold">
                  <span>{data['posted_by']['username']}</span>
                  <span class="ml-auto">{data['post_date']}</span>
                </span>
              </a>
            </div>
          </div>

        
          <div class="timeline-post">
            <div class="timeline-post-marker"></div>

            <div class="timeline-post-content card shadow-light-sm">
              <div class="card-body">
              
                <div class="avatar d-block mx-auto mt-n5">
                  <img src={`https://api.devzhive.com${data['image']}`} class="rounded-circle avatar-image" alt=""/>
                </div>

              
                <a href="#!" class="h4 text-dark d-block font-weight-normal text-center text-decoration-none mt-4">
                  {data['title']}
                </a>

              
                <p class="text-center text-muted font-size-sm mt-2">
                  {data['posted_by']['username']}
                </p>


              
                <div class="text-center mt-4">
                  <a href={`blog/posts/${data['slug']}`} class="btn btn-pistachio hover-lift-light">
                    Read more »
                  </a>
                </div>
              </div>
            </div>
          </div>
         </div>
        ):(
          <div class="timeline">
          

        
          <div class="timeline-post mt-5">
            <div class="timeline-post-marker"></div>

            <div class="timeline-post-content card shadow-light-sm">
              <div class="card-body">
              
                <div class="avatar d-block mx-auto mt-n5">
                  <img src={`https://api.devzhive.com${data['image']}`} class="rounded-circle avatar-image" alt=""/>
                </div>

              
                <a href="#!" class="h4 text-dark d-block font-weight-normal text-center text-decoration-none mt-4">
                  {data['title']}
                </a>

              
                <p class="text-center text-muted font-size-sm mt-2">
                  {data['posted_by']['username']}
                </p>


              
                <div class="text-center mt-4">
                  <a href={`blog/posts/${data['slug']}`} class="btn btn-pistachio hover-lift-light">
                    Read more »
                  </a>
                </div>
              </div>
            </div>
          </div>
            <div class="timeline-post">
            <div class="timeline-post-marker"></div>

            <div class="timeline-post-content hover-mask h-300 rounded-lg shadow-light">
            
              <div class="hover-mask-image" style={{backgroundImage:`url(${'https://api.devzhive.com'}${data['image']})`}}>
              </div>

              <a href="#!" class="hover-mask-content d-flex flex-column opacity-100 text-decoration-none p-4">  
                <span class="d-flex text-white text-uppercase-xs font-weight-bold">
                  <span>{data['posted_by']['username']}</span>
                  <span class="ml-auto">{data['post_date']}</span>
                </span>
              </a>
            </div>
          </div>
        
         
         </div>

        )
   
  ))
) : (
  <span className="loader"></span>
)}

       
        

      
       

        <div class="clearfix"></div>
          <div class="position-relative text-center mt-7">
            <a href="#!" class="btn btn-purple text-uppercase-bold-sm rounded-lg">
              <i class="fas fa-chevron-left mr-2"></i>
              See older posts
            </a>
          </div>
      </div>
   
  </section>



    )
}
export default Featured_post;
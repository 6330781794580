function Footer(){
    return(
        <footer class="footer footer-light bg-light py-7 mt-5">
        <div class="container text-center">
          <div class="row">
            <div class="col-12 col-md">
              <h6 class="footer-header mb-4 pb-2">
                DEVZHIVE
              </h6>
              <a href="/" class="footer-link font-size-normal">
                Portfolio
              </a>
              <a href="/" class="footer-link font-size-normal mt-2">
                Our clients
              </a>
              <a href="/" class="footer-link font-size-normal mt-2">
                Careers
              </a>
            </div>
    
          
    
            <div class="col-12 col-md mt-5 mt-md-0">
              <h6 class="footer-header mb-4 pb-2">
                Contact
              </h6>
              <a href="/" class="footer-link font-size-normal">
                +92 307 4992574
              </a>
              <a href="/" class="footer-link font-size-normal mt-2">
                contact@gmail.com
              </a>
            </div>
    
         
          </div>
    
          <div class="d-flex justify-content-center mt-6">
            <a href="/" class="mx-2 hover-lift-light text-decoration-none">
              <span class="icon-circle footer-social-icon">
                <i class="fab fa-twitter fa-lg"></i>
              </span>
            </a>
            <a href="/" class="mx-2 hover-lift-light text-decoration-none">
              <span class="icon-circle footer-social-icon">
                <i class="fab fa-facebook-square fa-lg"></i>
              </span>
            </a>
            <a href="/" class="mx-2 hover-lift-light text-decoration-none">
              <span class="icon-circle footer-social-icon">
                <i class="fab fa-github fa-lg"></i>
              </span>
            </a>
            <a href="/" class="mx-2 hover-lift-light text-decoration-none">
              <span class="icon-circle footer-social-icon">
                <i class="fab fa-dribbble fa-lg"></i>
              </span>
            </a>
          </div>
          <div class="mt-6">
            <p class="footer-text mb-2">
              All rights reserved. © 2020.
            </p>
            <div class="d-flex justify-content-center">
              <a href="/" class="footer-text mx-3">
                Privacy & terms
              </a>
              <a href="/" class="footer-text mx-3">
                Terms of service
              </a>
            </div>
          </div>
    
        </div>
      </footer>
    )
}

export default Footer;
import logo from './logo.svg';
import './App.css';
import Home from './Home';
import Contact from './Contact';
import { Routes, Route } from 'react-router-dom';
import Blog from './BLog';
import Project from './Project_details';
import Blog_details from './Blog_details';
import Portfolio_Navbar from './components/portfolios/Navbar_port';
import About from './About';
function App() {
  return (
    <div className="App">
      <Routes>

        <Route path='/' Component={Home} />
        <Route path='contact' Component={Contact} />
        <Route path='blogs/' Component={Blog} />
        <Route path='/project/:slug' Component={Project} />
        <Route path='/blog/posts/:slug' Component={Blog_details} />
        <Route path='/porfolio' Component={Portfolio_Navbar} />
        <Route path='/about' Component={About} />

      </Routes>



    </div>
  );
}

export default App;

import backgroundimg from './22.jpeg';
function AboutUs() {
    return (
        <section class="container">
            <div class="row h-lg-700">
                <div class="col-lg-6 d-flex align-items-center pr-lg-5">

                    <div class="pt-8 pt-lg-4">
                        <h1 class="text-darkblue fx-fade-up">
                            We are Devzhive, a multidisciplinary creative team from Copenhagen
                        </h1>

                        <p class="text-muted mt-4 fx-fade-up animation-delay-2">
                            Our goal is to deliver great products and experiences that move people and make them enjoy tech and design.
                        </p>

                        <div class="fx-fade-up animation-delay-4">
                            <a href="/contact" class="btn btn-success btn-lg text-uppercase-bold-sm hover-lift-light mt-4">
                                Get in touch
                            </a>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 p-lg-0 mt-5 mt-lg-0">
                    <div class="bg-cover vw-lg-50 h-400 h-lg-100" style={{ backgroundImage: `url(${backgroundimg})` }}></div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs;
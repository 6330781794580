import Image53 from './resources/53.jpeg';
import Image54 from './resources/54.jpeg';
import Image55 from './resources/55.jpeg';


function Older_post(){
    return (
         <footer class="footer footer-dark bg-dark mt-8">
    <div class="container text-center">
      <div class="row">
        <div class="col-12 col-md">
          <h6 class="footer-header mb-4 pb-md-2">
            Inertia
          </h6>
          <a href="#" class="footer-link">
            Landing pages
          </a>
          <a href="#" class="footer-link mt-2">
            Blog pages
          </a>
          <a href="#" class="footer-link mt-2">
            Ecommerce layout
          </a>
        </div>

        <div class="col-12 col-md mt-5 mt-md-0">
          <h6 class="footer-header mb-4 pb-md-2">
            Company
          </h6>
          <a href="#" class="footer-link">
            Contact Us
          </a>
          <a href="#" class="footer-link mt-2">
            About
          </a>
          <a href="#" class="footer-link mt-2">
            Careers
          </a>
        </div>

        <div class="col-12 col-md mt-5 mt-md-0">
          <h6 class="footer-header mb-4 pb-md-2">
            Products
          </h6>
          <a href="#" class="footer-link">
            Theme Landings
          </a>
          <a href="#" class="footer-link mt-2">
            Theme Support
          </a>
          <a href="#" class="footer-link mt-2">
            Theme Documentation
          </a>
        </div>

        <div class="col-12 col-md mt-5 mt-md-0">
          <h6 class="footer-header mb-4 pb-md-2">
            Resources
          </h6>
          <a href="#" class="footer-link">
            My Account
          </a>
          <a href="#" class="footer-link mt-2">
            Branding Resources
          </a>
        </div>

        <div class="col-12 col-md mt-5 mt-md-0">
          <h6 class="footer-header mb-4 pb-md-2">
            Support
          </h6>
          <a href="#" class="footer-link">
            Frequently Asked Questions
          </a>
          <a href="#" class="footer-link mt-2">
            Email Us
          </a>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-6">
        <a href="#" class="hover-lift-light text-decoration-none mx-2">
          <span class="icon-circle footer-social-icon">
            <i class="fab fa-twitter fa-lg"></i>
          </span>
        </a>
        <a href="#" class="hover-lift-light text-decoration-none mx-2">
          <span class="icon-circle footer-social-icon">
            <i class="fab fa-facebook-square fa-lg"></i>
          </span>
        </a>
        <a href="#" class="hover-lift-light text-decoration-none mx-2">
          <span class="icon-circle footer-social-icon">
            <i class="fab fa-github fa-lg"></i>
          </span>
        </a>
        <a href="#" class="hover-lift-light text-decoration-none mx-2">
          <span class="icon-circle footer-social-icon">
            <i class="fab fa-dribbble fa-lg"></i>
          </span>
        </a>
      </div>

      <div class="mt-6">
        <p class="footer-text mb-2">
          All rights reserved. © 2020.
        </p>
        <div class="d-flex justify-content-center">
          <a href="#" class="footer-text mx-3">
            Privacy & terms
          </a>
          <a href="#" class="footer-text mx-3">
            Terms of service
          </a>
        </div>
      </div>

      <div class="mt-6">
        <p class="footer-text text-uppercase font-weight-bold">
          Made with love from
        </p>
        <div class="footer-brand">
          <svg width="115px" height="21px" viewBox="0 0 115 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <path d="M0.43,20 L0.43,1.046 L4.075,1.046 L4.075,20 L0.43,20 Z M22.896,1.046 L26.514,1.046 L26.514,20.027 L25.677,20.27 L14.256,8.741 L14.256,20 L10.665,20 L10.665,1.019 L11.502,0.776 L22.896,12.305 L22.896,1.046 Z M36.479,16.922 L44.93,16.922 L44.93,20 L32.834,20 L32.834,1.046 L44.66,1.046 L44.66,4.124 L36.479,4.124 L36.479,8.849 L43.229,8.849 L43.229,11.927 L36.479,11.927 L36.479,16.922 Z M63.589,7.094 C63.589,9.578 62.023,11.549 60.241,12.44 L65.101,20 L60.673,20 L56.677,13.466 C55.867,13.547 55.057,13.574 54.139,13.493 L54.139,20 L50.494,20 L50.494,1.208 C59.458,-0.331 63.589,2.423 63.589,7.094 Z M54.139,4.232 L54.139,10.334 C58.108,10.739 59.836,9.254 59.836,7.04 C59.836,5.015 58,3.638 54.139,4.232 Z M82.194,1.046 L82.194,4.124 L76.875,4.124 L76.875,20 L73.23,20 L73.23,4.124 L67.938,4.124 L67.938,1.046 L82.194,1.046 Z M87.38,20 L87.38,1.046 L91.025,1.046 L91.025,20 L87.38,20 Z M99.775,20 L95.725,20 L104.5,0.857 L105.769,0.857 L114.49,20 L110.467,20 L109.036,16.922 L101.206,16.922 L99.775,20 Z M105.121,7.796 L102.502,13.898 L107.713,13.898 L105.121,7.796 Z" fill="currentColor"></path>
    </svg>
        </div>
      </div>
    </div>
  </footer>

    )
}
export default Older_post;
import Pic1 from './ui8-1.jpg';
import Pic2 from './mockups-design-1.jpg';

import { useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';
import api from '../../../Api';

function Main_Wrapper(){
    const { slug } = useParams();
     const [project, setProject] = useState(null);
     const [moreprojects, setProjects] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        
        const response = await api.get(`/projects/${slug}/`);
        setProject(response.data);
      
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };
    fetchProject();
  }, [slug]);

  useEffect(()=>{
    const fetchallProject = async () =>{
    try{
      const response = await api.get('/projects/featuredprojects/');
      setProjects(response.data)
      console.log(response.data);
    }
    catch(error){
      console.log("Error fetching data : ", error);
    }
  }
  fetchallProject();
  })
    return(
        <section class="bg-light position-relative z-index-2 pt-5 pt-lg-7" style={{textAlign:'left'}}>
    <div class="container">
      <div class="fx-fade-up animation-delay-1">
       <span className="svg-icon svg-icon-xxl text-info ml-n2">
      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
        <title>ionicons-v5-e</title>
        <line x1="400" y1="256" x2="112" y2="256" style={{ fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px' }} />
      </svg>
    </span>
        <h1 class="display-4 font-weight-bold text-darkblue">
          {project ? project.project_name : <span class="loader"></span>}
        </h1>
      </div>

      <div class="row mt-5 fx-fade-up animation-delay-3">
        <div class="col-md-7">
          <h4 class="text-darkblue font-weight-normal line-height-lg w-xl-75">
          {project ? project.project_slogan : <span class="loader"></span>}
          </h4>
          <p class="text-muted mt-4">
          {project ? project.description : <span class="loader"></span>}
          </p>
        </div>

        <div class="col-md-3 offset-md-2 mt-4 mt-md-0">
          <h5 class="text-muted text-uppercase-bold-sm">
            Role
          </h5>
          <p class="text-darkblue">
          {project ? project.role : <span class="loader"></span>}
          </p>
        <h5 class="text-muted text-uppercase-bold-sm">
            Project_status
          </h5>
          <p class="text-darkblue">
          {project ? project.project_status : <span class="loader"></span>}
          </p>

          <h5 class="text-muted text-uppercase-bold-sm mt-4">
            {project ? project.deliverables : <span class="loader"></span>}
          </h5>
          <p class="text-darkblue mb-0">
            Branding, Website & Mobile
          </p>
          <a href={project ?project.website_url : ''} class="d-inline-block text-info text-decoration-none border-bottom border-info mt-4 pb-2">
            Visit website
          </a>
        </div>
      </div>
    </div>
    <div class="container mt-6 fx-fade-up animation-delay-5">
    {project ? project.sections.map((data, index) => (
  index % 2 === 0 ? (
    <div className="row" key={index}>
      <div className="col-md-6">
        <a href={data.upload} className="d-block hover-lift-light" data-fancybox="gallery1">
          {project ? <img src={data.upload} className="img-fluid" alt="Image" /> : <span className="loader"></span>}
        </a>
      </div>
      <div className="col-md-6 mt-4 mt-md-0 p-4">
        <p className="lead text-justify text-darkblue mb-0">
          {project ? data.value : <span className="loader"></span>}
        </p>
      </div>
    </div>
  ) : (
    <div className="row" key={index}>
     
      <div className="col-md-6 mt-4 mt-md-0 p-4">
        <p className="lead text-justify text-darkblue mb-0">
          {project ? data.value : <span className="loader"></span>}
        </p>
      </div>

       <div className="col-md-6">
        <a href={data.upload} className="d-block hover-lift-light" data-fancybox="gallery1">
          {project ? <img src={data.upload} className="img-fluid" alt="Image" /> : <span className="loader"></span>}
        </a>
      </div>
    </div>
  )
)) : <span className="loader"></span>}


      <div class="row">
        <div class="col-md-6">
          <a href="assets/img/screenshots/project-6.jpg" class="d-block hover-lift-light" data-fancybox="gallery1">
            <img src={Pic2} class="img-fluid" alt=""/>
          </a>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <a href="assets/img/screenshots/project-7.jpg" class="d-block hover-lift-light" data-fancybox="gallery1">
            <img src={Pic2} class="img-fluid" alt=""/>
          </a>
        </div>
      </div>
    </div>

    <div class="container text-left mt-8 mt-md-9">
      <span className="svg-icon svg-icon-xxl text-purple ml-n2">
      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
        <title>ionicons-v5-e</title>
        <line x1="400" y1="256" x2="112" y2="256" stroke="currentColor" strokeWidth="32" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      </svg>
    </span>
      <h3>
        Browse more projects
      </h3>

      <div class="row mt-5">
        
        {moreprojects && moreprojects.map((data, index) => (
        <div class="col-lg-4 mb-4 pb-2">
          <a href="project-showcase.html" class="hover-mask h-250 h-lg-300">
            <span class="hover-mask-content d-flex justify-content-center align-items-center overlay overlay-white overlay-90">
              <span class="position-relative text-darkblue text-center">
                <h4 class="hover-mask-content-up">
                 {data['project_name']}
                </h4>
                <p class="font-size-sm text-uppercase hover-mask-content-up">
                   {data['project_slogan']}
                </p>
                 <p class="font-size-sm text-uppercase hover-mask-content-up" style={{textAlign:'start', position:'absolute', top:'-68px', left:'12rem'}}>
                          <i class="fas fa-heart fa-lg">{data['likes']}</i>
                    </p>
                <a href={'/project/'+data['slug']} class="btn btn-info rounded-pill mt-3 hover-mask-content-down">
                  See project
                </a>
              </span>
            </span>

            <span class="hover-mask-image jarallax" data-jarallax data-speed="0.8" style={{ backgroundImage: `url(${'https://api.devzhive.com'}${data['image']})` }}></span>
          </a>
        </div>
       
 ))}

        
      
      
    </div>

    <div class="container text-center text-lg-right mt-7 pb-8">
       <span className="svg-icon svg-icon-xxl text-purple ml-n2">
      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
        <title>ionicons-v5-e</title>
        <line x1="400" y1="256" x2="112" y2="256" style={{ fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px' }} />
      </svg>
    </span>
      <h2>
        Have a great project in mind?
      </h2>
      <p class="text-secondary lead">
        I'd love to help make it real.
      </p>
      <a href="/contact" class="btn btn-warning btn-lg rounded-pill text-uppercase-bold-sm hover-lift-light mt-4">
        Get in touch
        <i class="fa fa-arrow-right fa-xs ml-2"></i>
      </a>
    </div>
     </div>
  </section>
    )
}

export default Main_Wrapper;
import { useState } from "react";

function Navbar(){
    const [isNavToggled, setIsNavToggled] = useState(false);

  const handleToggleNav = () => {
    setIsNavToggled(!isNavToggled);
  };
    return(
  <nav className={`navbar navbar-expand-lg navbar-light ${isNavToggled ? 'navbar-toggled':''}`} role="navigation" style={{textAlign:'left'}}>
  
        <div class="container position-relative">
      
      
          
            <a class="navbar-brand" href="./index.html">
          
          
    
          
           <h1 class="mt-3">DEVZHIVE</h1>
          
          
          </a>
    
      <button class="navbar-toggler" onClick={handleToggleNav} data-toggle="collapse" data-target="#navbar-collapse">
            <span class="navbar-toggler-icon"></span>
          </button>
    
        
      <div  className={`collapse navbar-collapse justify-content-end mt-2 mt-lg-0 ${isNavToggled ? 'show' : ''}`} id="navbar-collapse">
            
              <ul class="navbar-nav ">
                <li class="nav-item ml-lg-3 mt-3 mt-lg-0">
                  <a class="nav-link dropdown-toggle"  href="/">
                    Home
                  </a>
                </li> 
                <li class="nav-item ml-lg-3 mt-3 mt-lg-0">
                  <a class="nav-link dropdown-toggle"  href="/blogs" >
                    Blogs
                  </a>
                </li> 
                 <li class="nav-item ml-lg-3 mt-3 mt-lg-0">
              <a class="btn btn-sm btn-primary hover-lift-light mt-1" href="/contact">
                Contact US
              </a>
            </li>
              
    
           
              
              </ul>
            
            
        
        
          </div>
    
        </div> 
      </nav>
    )
}

export default Navbar;
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.devzhive.com', // Set your API base URL
  headers: {
    'Content-Type': 'application/json',
    // Add any other headers you need
  },
  timeout: 5000, // Set a timeout for requests
});

export default api;
function MidSection() {
    return (
        <section class="bg-light py-6 py-lg-8 mt-6 mt-lg-0">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <div class="icon-circle icon-circle-lg bg-pistachio text-dark">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="512"
                                height="512"
                                viewBox="0 0 512 512"
                            >
                                <title>ionicons-v5-m</title>
                                <rect
                                    x="280.48"
                                    y="122.9"
                                    width="63.03"
                                    height="378.2"
                                    rx="10"
                                    ry="10"
                                    transform="translate(-129.23 312) rotate(-45)"
                                    style={{
                                        fill: 'none',
                                        stroke: '#000',
                                        strokeMiterlimit: 10,
                                        strokeWidth: '32px',
                                    }}
                                />
                                <rect
                                    x="180.35"
                                    y="164.45"
                                    width="63.29"
                                    height="95.1"
                                    transform="translate(-87.81 212) rotate(-45)"
                                />
                                <line
                                    x1="48"
                                    y1="192"
                                    x2="96"
                                    y2="192"
                                    style={{
                                        stroke: '#000',
                                        strokeLinecap: 'round',
                                        strokeMiterlimit: 10,
                                        strokeWidth: '32px',
                                    }}
                                />
                                <line
                                    x1="90.18"
                                    y1="90.18"
                                    x2="124.12"
                                    y2="124.12"
                                    style={{
                                        stroke: '#000',
                                        strokeLinecap: 'round',
                                        strokeMiterlimit: 10,
                                        strokeWidth: '32px',
                                    }}
                                />
                                <line
                                    x1="192"
                                    y1="48"
                                    x2="192"
                                    y2="96"
                                    style={{
                                        stroke: '#000',
                                        strokeLinecap: 'round',
                                        strokeMiterlimit: 10,
                                        strokeWidth: '32px',
                                    }}
                                />
                                <line
                                    x1="293.82"
                                    y1="90.18"
                                    x2="259.88"
                                    y2="124.12"
                                    style={{
                                        stroke: '#000',
                                        strokeLinecap: 'round',
                                        strokeMiterlimit: 10,
                                        strokeWidth: '32px',
                                    }}
                                />
                                <line
                                    x1="124.12"
                                    y1="259.88"
                                    x2="90.18"
                                    y2="293.82"
                                    style={{
                                        stroke: '#000',
                                        strokeLinecap: 'round',
                                        strokeMiterlimit: 10,
                                        strokeWidth: '32px',
                                    }}
                                />
                            </svg>
                        </div>

                        <h2 class="font-weight-normal mt-4">
                            A team of digital enthusiasts ready for the next challenge
                        </h2>

                        <p class="text-muted mt-4">
                            Over the past decade, we've helped hundreds of companies thrive in the digital world. Growing businesses through innovation is our goal.
                        </p>
                    </div>

                    <div class="col-lg-7 offset-lg-1 mt-4 mt-lg-0">
                        <div class="row g-0">
                            <div class="col-6 bg-white text-purple text-center py-5 py-md-7">
                                <h1 class="display-3">
                                    30k
                                </h1>
                                <p class="m-0">
                                    paying customers
                                </p>
                            </div>

                            <div class="col-6 bg-purple text-white text-center py-5 py-md-7">
                                <h1 class="display-3">
                                    $100m
                                </h1>
                                <p class="m-0">
                                    in funding
                                </p>
                            </div>

                            <div class="col-6 bg-purple text-white text-center py-5 py-md-7">
                                <h1 class="display-3">
                                    2012
                                </h1>
                                <p class="m-0">
                                    founded
                                </p>
                            </div>

                            <div class="col-6 bg-white text-purple text-center py-5 py-md-7">
                                <h1 class="display-3">
                                    99.8%
                                </h1>
                                <p class="m-0">
                                    uptime
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default MidSection;
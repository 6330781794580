import image from './22.jpeg'
import img1 from './1.jpeg';
import img2 from './2.jpeg'
import img3 from './3.jpeg';
import store from './store.png'
import ui_ux_img from './ui.png'
import api from '../../../Api';
import './card_style.css';


import React, { useEffect, useState } from 'react';
function Welcome() {
  const [Featured_projects, SetData] = useState([]);
  const [Blog_data, SetDatablog] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/projects/featuredprojects/');
        // Handle the response data
        console.log(response.data);
        SetData(response.data)
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/blog/featuredposts/');
        // Handle the response data
        console.log(response.data);
        SetDatablog(response.data)
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return (
    <section class="bg-gradient-light">

      <div class="pt-8 pt-lg-9">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 text-center">
              <div
                class="video-card card-skew-right h-300 overlay overlay-primary overlay-20"
                style={{ backgroundImage: `url(${image})` }}
              >
                <a
                  class="icon-circle icon-circle-lg bg-white text-darkblue hover-scale position-relative"
                  href="https://www.youtube.com/watch?v=1R8wg6dPK-Y"
                  data-fancybox
                >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M9.82866499,18.2771971 L16.5693679,12.3976203 C16.7774696,12.2161036 16.7990211,11.9002555 16.6175044,11.6921539 C16.6029128,11.6754252 16.5872233,11.6596867 16.5705402,11.6450431 L9.82983723,5.72838979 C9.62230202,5.54622572 9.30638833,5.56679309 9.12422426,5.7743283 C9.04415337,5.86555116 9,5.98278612 9,6.10416552 L9,17.9003957 C9,18.1765381 9.22385763,18.4003957 9.5,18.4003957 C9.62084305,18.4003957 9.73759731,18.3566309 9.82866499,18.2771971 Z"
                        fill="#000000"
                      ></path>
                    </g>
                  </svg>
                </a>
              </div>
            </div>

            <div class="col-lg-6 pl-lg-6 mt-6 mt-lg-0">

              <div class="d-flex">
                <span class="svg-icon svg-icon-xl text-primary">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
                        fill="#000000"
                        opacity="0.3"
                      ></path>
                      <path
                        d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(11.959697, 18.661508) rotate(-270.000000) translate(-11.959697, -18.661508) "
                      ></path>
                    </g>
                  </svg>




                </span>
                <div class="flex-1 ml-4">
                  <h6 class="text-darkblue text-uppercase font-weight-bold">
                    Real time updates
                  </h6>
                  <p class="text-muted">
                    Enjoy a faster workflow with live browser reloads any time
                    you make a new change.
                  </p>
                </div>
              </div>


              <div class="d-flex mt-4 mt-xl-5">
                <span class="svg-icon svg-icon-xl text-primary">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
                        fill="#000000"
                        opacity="0.3"
                      ></path>
                      <path
                        d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(11.959697, 18.661508) rotate(-270.000000) translate(-11.959697, -18.661508) "
                      ></path>
                    </g>
                  </svg>




                </span>
                <div class="flex-1 ml-4">
                  <h6 class="text-darkblue text-uppercase font-weight-bold">
                    Free updates and support
                  </h6>
                  <p class="text-muted">
                    Get free lifetime updates of new components, layouts, page
                    designs and an attentive technical support.
                  </p>
                </div>
              </div>


              <div class="d-flex mt-4 mt-xl-5">
                <span class="svg-icon svg-icon-xl text-primary">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
                        fill="#000000"
                        opacity="0.3"
                      ></path>
                      <path
                        d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(11.959697, 18.661508) rotate(-270.000000) translate(-11.959697, -18.661508) "
                      ></path>
                    </g>
                  </svg>
                </span>
                <div class="flex-1 ml-4">
                  <h6 class="text-darkblue text-uppercase font-weight-bold">
                    Great variety of designs
                  </h6>
                  <p class="text-muted mb-0">
                    Launch a complete website with 10 ready to use landing pages
                    and 40+ support pages. Each one with unique ideas and
                    layouts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="mt-0">
        <div class="container_card text-center text-lg-left">


          <h1 class="text-darkblue font-weight-normal">
            Our Serviecs
          </h1>

          <section class="card__container">


            <div class="card__bx card__1">
              <div class="card__data">
                <div class="card__icon">
                  <div class="card__icon-bx">
                    <i class="fa fa-mobile" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="card__content">
                  <h3>Mobile Development</h3>
                  <p>Feature-rich options for mobile apps on Android, iOS, and other platforms that are aimed at any industry.</p>

                </div>
              </div>
            </div>
            <div class="card__bx card__2">
              <div class="card__data">
                <div class="card__icon">
                  <div class="card__icon-bx"><i class="fa fa-code" aria-hidden="true"></i></div>
                </div>
                <div class="card__content">
                  <h3>Web Development</h3>
                  <p>Modern, feature-rich, and responsive cross-platform and cross-browser interactive web apps.
                    Cross-platform and cross-browser interactive web apps that are contemporary, feature-rich, and responsive</p>

                </div>
              </div>
            </div>
            <div class="card__bx card__3">
              <div class="card__data">
                <div class="card__icon">
                  <div class="card__icon-bx"><i class="fa fa-wand-magic-sparkles"></i></div>
                </div>
                <div class="card__content">
                  <h3>UI/UX Designing</h3>
                  <p>Transforming visions into captivating experiences - Unleash the full potential of your digital presence with our expert UI/UX designing services..</p>

                </div>
              </div>
            </div>
            <div class="card__bx card__3">
              <div class="card__data">
                <div class="card__icon">
                  <div class="card__icon-bx"><i class="fa-solid fa-star"></i></div>
                </div>
                <div class="card__content">
                  <h3>Social Media Marketing</h3>
                  <p>Ignite your brand's presence and fuel growth with our strategic and results-driven social media marketing solutions.</p>

                </div>
              </div>
            </div>
            <div class="card__bx card__2">
              <div class="card__data">
                <div class="card__icon">
                  <div class="card__icon-bx"><i class="fa fa-search" aria-hidden="true"></i></div>
                </div>
                <div class="card__content">
                  <h3>QA & Testing</h3>
                  <p>Your company will stay afloat if you can identify and fix technical problems using accepted industry practices.</p>

                </div>
              </div>
            </div>
            <div class="card__bx card__1">
              <div class="card__data">
                <div class="card__icon">
                  <div class="card__icon-bx">
                    <img src={store} style={{ filter: 'invert(1)' }} width='50px' />
                  </div>
                </div>
                <div class="card__content">
                  <h3>Product Management</h3>
                  <p>Supporting and overseeing a product's lifecycle processes to ensure that worthwhile products are released onto the market

                  </p>

                </div>
              </div>
            </div>

          </section>

        </div>
      </div>


      <div class="pt-7 pb-6 mt-7" style={{ background: '#374767' }}>
        <div class="container">
          <div class="text-center">
            <h5
              class="badge bg-warning text-darkblue text-uppercase-bold-sm pt-2"
            >
              Projects
            </h5>
          </div>

          <div class="row row-cols-2 text-center mt-5">
            {Featured_projects.map((data, index) => (
              <div class="col mb-4 pb-3">
                <a href={'/project/' + data['slug']} class="d-block">
                  <img
                    src={`https://api.devzhive.com${data['image']}`}
                    class="img-fluid rounded-lg shadow-sm hover-lift-light lazyload"
                    loading="lazy"
                  />
                </a>
                <p class="lead text-white mt-4"> {data['project_name']}</p>
              </div>
            ))}

          </div>

          <div class="text-center mt-5">
            <h5
              class="badge bg-warning text-darkblue text-uppercase-bold-sm pt-2"
            >
              Blogs
            </h5>
          </div>

          <div class="row row-cols-2 text-center mt-5">
            {Blog_data.map((data, index) => (
              <div class="col mb-4 pb-3">
                <a href={'/blog/posts/' + data['slug']} class="d-block">
                  <img
                    src={`https://api.devzhive.com${data['image']}`}
                    class="img-fluid rounded-lg shadow-sm hover-lift-light lazyload"
                    loading="lazy"
                  />
                </a>
                <p class="lead text-white mt-4"> {data['title']}</p>
              </div>
            ))}

          </div>







        </div>
      </div>


    </section>

  )
}
export default Welcome;
import backgroundImage from './4.jpeg';
import api from '../../../Api';
import kingzmanga from './kingzmanga.png';
import tornovertext from './tornovertex.png'


import React, { useEffect, useState } from 'react';
function Section() {

  return (
    <section class="bg-light position-relative py-8 py-lg-11 py-xxl-12">

      <div class="svg-divider svg-divider-bottom text-white">
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 2000 350"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon fill="currentColor" points="2000 0 2000 350 0 350"></polygon>
        </svg>
      </div>

      <div class="container">
        <div class="row">

          <div class="col-lg-6 text-center text-lg-left pr-xl-5">
            <div class="fx-fade-up animation-delay-2">
              <h3 class="text-muted text-uppercase-xs">
                Multipurpose Bootstrap 5 Theme
              </h3>

              <h1 class="text-darkblue font-weight-normal mt-4">
                Improve your next <span class="text-primary">web project</span>.
                Design, develop, and launch faster.
              </h1>
            </div>

            <p
              class="lead text-muted w-lg-80 mt-4 fx-fade-up animation-delay-4"
            >
              Create your new website with a modern, functional and flexible
              design.
            </p>

            <div class="mt-5 fx-fade-up animation-delay-6">
              <a
                href="#"
                class="btn btn-primary text-uppercase font-size-sm shadow-sm hover-lift pt-2 mr-3"
              >
                Learn more
              </a>
              <a
                href="docs/index.html"
                class="btn btn-white text-uppercase font-size-sm font-weight-bold shadow-sm hover-lift pt-2"
              >
                View Docs
              </a>
            </div>
          </div>


          <div class="col-lg-6 position-relative d-none d-lg-block">

            <div
              class="position-lg-absolute right-0 bottom-0 mb-lg-n8 fx-fade-up animation-delay-6"
            >
              <a

                class="atvImg d-block"
                style={{ width: '379px', height: '247px' }}

              >
                <img
                  src={tornovertext}
                  style={{ width: '379px' }}
                />
                <span
                  class="atvImg-layer"
                  data-img="assets/img/screenshots/atv-2.jpg"
                ></span>
              </a>
            </div>

            <div
              class="position-lg-absolute top-0 right-0 mt-lg-n6 mr-lg-n7 fx-fade-up animation-delay-2"
            >
              <a

                class="atvImg d-block"
                style={{ width: '379px', height: '250px' }}
              >
                <img
                  src={kingzmanga}
                  style={{ width: '379px' }}
                />
                <span
                  class="atvImg-layer"
                  data-img="assets/img/screenshots/atv-3.jpg"
                ></span>
              </a>
            </div>

            <div
              class="position-lg-absolute top-0 left-0 mt-lg-4 ml-lg-3 fx-fade-up animation-delay-4"
            >
              <a

                class="atvImg d-block"
                style={{ width: '379px', height: '250px' }}
              >
                <img
                  src={tornovertext}
                  style={{ width: '379px' }}
                />
                <span
                  class="atvImg-layer"
                  data-img="assets/img/screenshots/atv-1.jpg"
                ></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default Section;
import Navbar from "./components/Blog/Navbar/Navbar";
import Featured_post from "./components/Blog/Featured_Post/Featured_post";
import Older_post from "./components/Blog/Older_post/Older_post";
import Subscribe from "./components/Blog/Subscribe/Subscribe";
import Footer from "./components/Contact/Footer/Footer";

function Blog(){
    return (
        <div className="Blog">
        <Navbar/>
        <Featured_post/>
        <Older_post/>
        </div>
    )
}

export default Blog;
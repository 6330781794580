import './App.css';
import Navbar from './components/Home/navbar/navbar';
import Section from './components/Home/MainSection/Section';
import Portfolio from './components/Home/portfolio/Protfolio';
import Welcome from './components/Home/Welcome/Welcome';
import Testimonials from './components/Home/Testimonials/Testimonials';
import FromBlog from './components/Home/Blog/FromBLog';
import GetInTouch from './components/Home/GetInTouch/Get_in_touch';
import Footer from './components/Home/Footer/footer';

function Home() {
  return (
    <div className="App">
      <Navbar />
      <Section />
      <Welcome />

      <GetInTouch />
      <Footer />

    </div>
  );
}

export default Home;

import AboutUs from "./components/AboutUs/AboutUs";
import Navbar from "./components/Blog/Navbar/Navbar";
import MidSection from "./components/AboutUs/MidSection";
import Footer from "./components/Contact/Footer/Footer";
import SecondMidSection from "./components/AboutUs/SecondMidSections";
import Map from "./components/AboutUs/map";
function About() {
    return (
        <div>
            <Navbar />
            <AboutUs />
            <MidSection />
            <SecondMidSection />
            <Map />
            <Footer />

        </div>
    )
}
export default About;
import Bg from './6.jpeg'
function GetInTouch() {
  return (
    <section class="bg-cover overlay overlay-darkblue overlay-20 py-8" style={{ backgroundImage: `url(${Bg})` }}>
      <div class="container position-relative text-center text-white">
        <h1>
          Want to hire us?
        </h1>
        <p class="lead">
          Have an idea for an awesome project?
          We'd love to help make it real.
        </p>
        <a href="contact-project.html" class="btn btn-warning btn-lg rounded-pill shadow hover-lift mt-5">
          Get in touch
          <i class="fas fa-arrow-right fa-xs ml-3"></i>
        </a>
      </div>
    </section>

  )
}
export default GetInTouch;
import Navbar from "./components/Contact/Navbar/Navbar";
import Intrduction from "./components/Contact/Introduction/Introdunction";
import Form from "./components/Contact/Form/Form";
import Footer from "./components/Home/Footer/footer";

function Contact(){
    return(
        <div className="Contact">

        <Navbar/>
        <Intrduction/>
        <Form/>
        <Footer/>
        </div>

    );
}
export default Contact;
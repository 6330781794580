import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '../../../Api';
function Header_blog() {
  const { slug } = useParams();
  const [blogPostsdetails, setblogPostsdetails] = useState(null);
  useEffect(() => {
    const fetchProject = async () => {
      try {

        const response = await api.get(`blog/posts/${slug}/`);
        setblogPostsdetails(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };
    fetchProject();
  }, [slug]);

  return (
    <div>
      <section class="bg-cover position-relative h-550 pt-11 jarallax" data-jarallax data-speed="0.8" style={{ backgroundImage: `url(${'https://api.devzhive.com'}${blogPostsdetails ? blogPostsdetails['image'] : ""})` }}>
        <div class="container">

          <h1 class="display-4 text-white text-center fx-fade-up">
            {blogPostsdetails ? blogPostsdetails.title : <span class="loader"></span>} <br />Shortness of Life
          </h1>
        </div>


        <div class="position-absolute bottom-0 w-100 mb-4">
          <div class="container d-flex flex-column flex-sm-row align-items-center">
            <div class="d-flex">
              <div class="avatar mr-4">
                <img src={`https://api.devzhive.com${blogPostsdetails ? blogPostsdetails['image'] : ""}`} class="avatar-image rounded-circle" alt="" />
              </div>
              <div class="flex-1">
                <span class="text-white text-uppercase-bold-sm">
                  {blogPostsdetails ? blogPostsdetails.posted_by.username : <span class="loader"></span>}
                </span>
                <span class="text-light opacity-90 font-size-sm d-block">
                  {blogPostsdetails ? blogPostsdetails.post_date : <span class="loader"></span>}
                </span>
              </div>
            </div>


            <div class="mt-4 mt-sm-0 ml-sm-auto">
              <a href="#" class="icon-circle icon-circle-sm bg-white text-dark hover-lift-light mr-2">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="icon-circle icon-circle-sm bg-white text-dark hover-lift-light mr-2">
                <i class="fab fa-facebook"></i>
              </a>
              <a href="#" class="icon-circle icon-circle-sm bg-white text-dark hover-lift-light">
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </section>



      <section class="mt-6">
        <div class="container">
          {
            blogPostsdetails ? blogPostsdetails.sections.map((data, index) => (
              <div class="row justify-content-center">
                <div class="col-lg-8">

                  <p class="lead text-darkblue text-center border-bottom border-gray-100 pb-5">
                    {data.title}
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: data.value }}>

                  </div>

                  <img src={`https://api.devzhive.com${blogPostsdetails ? data['image']['file'] : ""}`} class="img-fluid rounded-lg shadow-light hover-lift-light my-2" alt={blogPostsdetails ? data['image']['file_alt'] : ""} />
                  <div class="text-center font-size-sm text-muted mt-2 mb-5">Haselschacher Buck, Vogtsburg, Germany</div>




                </div>
              </div>

            )) : <span class="loader"></span>
          }
          <div class="d-flex flex-column flex-sm-row align-items-center mt-6">
            <div class="d-flex">
              <div class="avatar mr-4">
                <img
                  src={
                    blogPostsdetails && blogPostsdetails['posted_by'] && blogPostsdetails['posted_by']['profile_image']
                      ? `https://api.devzhive.com${blogPostsdetails['posted_by']['profile_image']}`
                      : 'https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg'
                  }
                  className="avatar-image rounded-circle"
                  alt=""
                />
              </div>
              <div class="flex-1">
                <span class="text-dark text-uppercase-bold-sm">
                  {blogPostsdetails ? blogPostsdetails['posted_by']['username'] : <span class="loader"></span>}
                </span>
                <span class="text-muted opacity-90 font-size-sm d-block">
                  {blogPostsdetails ? blogPostsdetails['post_date'] : <span class="loader"></span>}
                </span>
              </div>
            </div>


            <div class="mt-4 mt-sm-0 ml-sm-auto">
              <a href="#" class="text-decoration-none text-muted mr-3">
                <i class="fab fa-twitter fa-lg"></i>
              </a>
              <a href="#" class="text-decoration-none text-muted mr-3">
                <i class="fab fa-facebook fa-lg"></i>
              </a>
              <a href="#" class="text-decoration-none text-muted">
                <i class="fab fa-instagram fa-lg"></i>
              </a>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}
export default Header_blog;
import Header_blog from "./components/Blogdetails/header/Header_blog_details"
import Navbar from "./components/Home/navbar/navbar";
import Footer from "./components/Home/Footer/footer";
function Blog_details(){
    return(
        <div className="Blog_details">
         <Navbar/>   
        <Header_blog/>
        <Footer/>
        </div>

    )
}
export default Blog_details;
function Form(){
    return(
        <section class="mt-5 fx-fade-up animation-delay-4">
        <div class="container border-bottom border-gray-200 pb-8">
          <form>
            <div class="row justify-content-center mt-6">
              <div class="col-md-3">
                <h5 class="mt-2 mb-4 mb-md-0">
                <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
        <title>ionicons-v5-e</title>
        <line
          x1="400"
          y1="256"
          x2="112"
          y2="256"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '32px',
          }}
        />
      </svg>
    </span>                  Your information
                </h5>
              </div>
              <div class="col-md-6 offset-md-1">
                <div class="mb-3">
                  <label class="form-label text-dark">
                    Your name:
                  </label>
                  <input type="text" class="form-control form-control-lg border-gray-200"/>
                </div>
                <div class="mb-3">
                  <label class="form-label text-dark">
                    Email address:
                  </label>
                  <input type="email" class="form-control form-control-lg border-gray-200"/>
                </div>
                <div class="mb-3">
                  <label class="form-label text-dark">
                    Phone number:
                  </label>
                  <input type="phone" class="form-control form-control-lg border-gray-200"/>
                </div>
                <div class="mb-3">
                  <label class="form-label text-dark">
                    Company:
                  </label>
                  <input type="text" class="form-control form-control-lg border-gray-200"/>
                </div>
              </div>
            </div>
    
            <div class="row mt-5 justify-content-center">
              <div class="col-md-3">
                <h5 class="mb-4 mb-md-0">
                <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
        <title>ionicons-v5-e</title>
        <line x1="400" y1="256" x2="112" y2="256" style={{ fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px' }}></line>
      </svg>
    </span>                  Project description
                </h5>
              </div>
              <div class="col-md-6 offset-md-1">
                <p class="text-dark">
                  Please describe your project with details, including objectives and requirements
                </p>
                <div class="mb-3">
                  <textarea rows="6" class="form-control form-control-lg border-gray-200"></textarea>
                </div>
              </div>
            </div>
    
            <div class="row mt-5 justify-content-center">
              <div class="col-md-3">
                <h5 class="mb-4 mb-md-0">
                <span className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
        <title>ionicons-v5-e</title>
        <line x1="400" y1="256" x2="112" y2="256" style={{ fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px' }}></line>
      </svg>
    </span>                  Project timeframe
                </h5>
              </div>
              <div class="col-md-6 offset-md-1">
                <p class="text-dark">
                  Is there a deadline for this project?
                </p>
                <div class="mb-3">
                  <input type="text" class="form-control form-control-lg border-gray-200"/>
                </div>
    
                <input type="submit" value="Send message" class="btn btn-block btn-lg btn-success shadow-lg hover-lift mt-5"/>
              </div>
            </div>
          </form>
        </div>
      </section>
    )
}

export default Form;